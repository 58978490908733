import React, { memo } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { NotFound } from '../pages/errorpages/NotFound';

function MapAllowedRoutes(routes: any) {
  const match = useRouteMatch("/dashboard");

  return (
    <Switch>
      {routes.routes.map((route: any) => {
        const { path, component: Component, children, title, permission, ...rest } = route;
        return (
          <Route {...rest} key={path} path={`${match?.path}${path}`}>
            <Component children={children} />
          </Route>
        )
      })}
      <Route component={NotFound} />
    </Switch>
  )
}

export default memo(MapAllowedRoutes);