import React, { useContext, useState } from 'react'
import { IUserFormValues } from '../../models/User';
import { RootStoreContext } from '../../stores/RootStore';
import { history } from '../../ApplicationRouter'
import { Field, Formik } from 'formik';
import { Button, Form, Message } from 'semantic-ui-react';
import { TextInput } from '../common/form/TextInput';
import { validationSchema } from './Login.Validation';
import "./LoginForm.Styles.scss"


export const LoginForm = () => {
  const rootStore = useContext(RootStoreContext);
  const [message, setMessage] = useState("");
  const { login } = rootStore.authenticationStore;

  const handleLogin = async (values: IUserFormValues) => {
    // var urlParams = new URLSearchParams(this.props.location.search);
    // const returnurl = sanitizeUrl(urlParams.get("returnurl") || "");;
    const response = await login(values, "/dashboard");
    setMessage(response.message.replace(/["]/g, ""));
    console.log(response);
    if (response.success) {
      history.push('/dashboard/transactions');
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
      >
        {(props) => (

          <Form onSubmit={props.handleSubmit}>
            <Field
              id="username"
              name="username"
              label="Username"
              placeholder="username"
              component={TextInput}
            />

            <Field
              id="password"
              name="password"
              label="password"
              component={TextInput}
              type="password"
            />

            <Button fluid type="submit">Inloggen</Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
