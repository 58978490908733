import React, { memo, useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Menu } from 'semantic-ui-react'
import './NavBar.styles.scss';
import { RootStoreContext } from '../../../stores/RootStore';

function SecureNavBar() {
  const rootStore = useContext(RootStoreContext);
  const { signOut } = rootStore.authenticationStore;


  const handleSignOut = () => {
    signOut();
  }

  return (
    <>
      <Menu>
        <Container>
          <Menu.Item header as={NavLink} exact to="/dashboard/transactions">
            Transactions
          </Menu.Item>
          
          <Menu.Menu position='right'>
            <Menu.Item name="Sign out" onClick={handleSignOut} />
          </Menu.Menu>
        </Container>
      </Menu>
    </>
  )
}
export default memo(SecureNavBar)
