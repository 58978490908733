import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Button, Container } from 'semantic-ui-react';
import { RootStoreContext } from '../../stores/RootStore';
import { dateFormatter } from '../../utils/DateFormatter';
import { DataView } from '../common/dataView/DataView';

export const TransactionList = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { getTransactions, transactions } = rootStore.transactionStore;


  useEffect(() => {
    getTransactions();
  }, [])

  const booleanFormatter = (params: any) => {
    const validated: boolean = params.value;
    return validated ? 'yes' : 'no';
  }

  function dateTimeFormatter(params: any) {
    const d = new Date(params.value);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${da}-${mo}-${ye}`;
  }

  const dateComparator = (date1: any, date2: any) => {
    var date1Number = _monthToNum(date1);
    var date2Number = _monthToNum(date2);


    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  // HELPER FOR DATE COMPARISON
  const _monthToNum = (date: any) => {
    date = date.slice(0,10);
    if (date === undefined || date === null || date.length !== 10) {
      return null;
    }

    const yearNumber = date.substring(0, 4);
    const monthNumber = date.substring(5, 7);
    const dayNumber = date.substring(8, 10);

    const result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    // 29/08/2004 => 20040829
    return result;
  }

  const getColumns = (): {}[] => {
    return [
      {
        headerName: 'Date',
        field: 'date',
        filter: 'agDateColumnFilter',
        valueFormatter: dateTimeFormatter,
        comparator: dateComparator,
        flex: .75,
        filterParams: {
          debounceMs: 500,
          suppressAndOrCondition: true,
          comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
            if (cellValue == null) {
              return 0;
            }

            const dateParts = cellValue.split('-');
            const year = Number(dateParts[0]);
            const month = Number(dateParts[1] - 1);
            const day = Number(dateParts[2].substring(0, 2));

            const cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
      { headerName: `Requesting party`, field: "rp" },
      { headerName: `Approving party`, field: "ap" },
      { headerName: `Attribute`, field: "atrtributeType" },
      { headerName: `Comparison`, field: "comparison" },
      { headerName: `Validated`, field: "validated", flex: .75, valueFormatter: booleanFormatter },
      { headerName: `Cancelled`, field: "cancelled", flex: .75, valueFormatter: booleanFormatter },
    ];
  }

  return (
    <Container>
      <DataView
        columns={getColumns()}
        data={transactions}
      />
    </Container>
  )
})
