import { IUserFormValues } from "../models/User";
import { RootStore } from "./RootStore";
import { localhostLogFetch, localhostLogFetchResult, removeSurroundingQuotes } from "../utils/General";
import { sanitizeUrl } from "../utils/Security";

export default class AuthenticationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    
  }

  login = async (values: IUserFormValues, redirectPath: string) => {
    const type = "POST";
    const url = `${process.env.REACT_APP_AUTH_URL}/login`;
    const body = JSON.stringify(values);
    localhostLogFetch(type, url, body);

    const response: any = await fetch(url,
      {
        method: type,
        cache: "no-cache",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        redirect: "follow",
        referrer: "TO_Frontend",
        body: body
      }
    );

    const success = response.status === 200;
    const responseText = await response.text();
    localhostLogFetchResult(type, url, responseText);

    let redirect = "";
    let message = "";

    if (success) {
      const responseBody = removeSurroundingQuotes(responseText);
      this.setToken(responseBody);
      //redirect = `/authentication/landing/?token=${responseBody}`;

      redirectPath = sanitizeUrl(redirectPath);
      if (redirectPath.length > 0) {
        //redirect += `&returnurl=${redirectPath}`;
        redirect += `${redirectPath}`;
      }
      message = "Authenticated, redirecting"
    } else {
      message = response.status === 401 ? responseText : "An error occurred";
    }

    return {
      success: success,
      message: message,
      redirect: redirect
    };
  }

  setToken(token: string) {
    this.rootStore.authorizationStore.setToken(token);
  }

  async signOut() {
    //this.setToken("");
    localStorage.setItem("to-authtoken", ""); // TODO gebruik de setToken vanuit AuthorizationStore
    window.location.href = "/";
    this.rootStore.authorizationStore.token = null;
    // const returnUrl = encodeURIComponent(process.env.PUBLIC_URL);
    // window.location.href = `${process.env.REACT_APP_AUTH_URL}/signout?returnUrl=${returnUrl}`; // TODO
  }
}