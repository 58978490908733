export const baseSubmit = async (type: string, url: string, token: string, data: any) => {
  const body = JSON.stringify(data);
  url = `${process.env.REACT_APP_API_URL}/${url}`;

  return await fetch(url, {
    method: type,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    redirect: "follow",
    referrer: "ToeristischeVerhuur_Frontend",
    body: body // body data type must match "Content-Type" header
  }).catch((error) => {
    console.log('ERROR: ' + type + ' ' + url, error);
  });
};

export const baseFetch = async (type: string, url: string, token: string) => {
  url = `${process.env.REACT_APP_API_URL}/${url}`;

  return await fetch(url, {
    method: type,
    headers: {
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`
    },
    redirect: "follow",
    referrer: "ToeristischeVerhuur_Frontend"
  }).catch((error) => {
    console.log('ERROR: ' + type + ' ' + url, error);
  });
};