import React, { useContext } from "react";
import { RootStoreContext } from '../../stores/RootStore';
import SecureNavBar from '../../components/common/navbar/SecureNavbar';
import { NotFound } from "../../pages/errorpages/NotFound";

export const SecureLayout = ( {children } ) => {
  const rootStore = useContext(RootStoreContext);

  if (!rootStore.authorizationStore.isAuthenticated()){
    return (
        <NotFound />
    );
  }

  return (
    <>
      <div className="main">
        <SecureNavBar />
        {children}
      </div>
    </>
  )
}