import React, { useState } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Button, Container, Segment } from "semantic-ui-react";
import './DataView.styles.scss'

export const DataView = ({ columns, data, actionsRenderer }: any) => {
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const clearFilters = () => {
    gridApi!.setFilterModel(null);
  };


  return (

    <div className="ag-theme-alpine" style={{ height: '60vh', width: '100%', lineHeight: 1, }}>
      
      <Container>
      <Button className="button-reset" primary onClick={() => clearFilters()}>Reset filters</Button>
      </Container>
      
      <AgGridReact
        defaultColDef={{
          flex: 1,
          sortable: true,
          resizable: false,
          filter: true,
        }}
        rowData={data}
        columnDefs={columns}
        onGridReady={onGridReady}
      >


      </AgGridReact>
    </div>
  )
}
