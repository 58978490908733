import { RootStore } from "./RootStore";
import { makeAutoObservable, runInAction } from 'mobx'
import { ITransaction } from "../models/Transactions";


export default class TransactionStore {
  rootStore: RootStore;
  transactions: ITransaction[];
  loadingTransactions: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.transactions = [];
    this.loadingTransactions = false;
    makeAutoObservable(this);
  }

  getTransactions = async () => {
    this.loadingTransactions = true;
    try {
      const { success, data }: { success: boolean, data: ITransaction[] } = await this.rootStore.baseApiService.get(`transactions/list`);

      if (success) {
        runInAction(() => {
          this.transactions = data;
          this.loadingTransactions = false;
        })
      }
    } catch (error) {
      runInAction(() => {
        this.loadingTransactions = false
      })
    }
  }

}