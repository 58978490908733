import React from 'react';
import { Container, Header } from 'semantic-ui-react'

export const NotFound = () => {

  return (
    <Container>
      <Header as="h1">
        'Not found'
      </Header>
    </Container>
  );
}
