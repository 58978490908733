const base64UrlDecode = function(str: string) {
  var output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += "==";
      break;
    case 3:
      output += "=";
      break;
    default:
      throw new Error("Illegal base64url string!");
  }

  try {
    return decodeURIComponent(
      atob(output).replace(/(.)/g, function(m, p) {
        var code = p
          .charCodeAt(0)
          .toString(16)
          .toUpperCase();
        if (code.length < 2) {
          code = "0" + code;
        }
        return "%" + code;
      })
    );
  } catch (err) {
    return atob(output);
  }
};
export { base64UrlDecode };
