import React from 'react'
import { Container, Segment } from 'semantic-ui-react'
import { TransactionList } from '../../../components/transactions/TransactionList'

export const TransactionsHome = () => {
  return (
    <Segment basic>
      <TransactionList/>
    </Segment>
  )
}
