import React, { useContext } from 'react';
import MapAllowedRoutes from './MapAllowedRoutes';
import PrivateRoutesConfig from './PrivateRoutesConfig';
import GetAllowedRoutes from './RouteUtils';
import { RootStoreContext } from '../stores/RootStore';
import { NotFound } from '../pages/errorpages/NotFound';
import { SecureLayout } from '../globalstyles/layouts/SecureLayout';

function PrivateRoutes() {
  const rootStore = useContext(RootStoreContext);

  if (!rootStore.authorizationStore.isAuthenticated()) {
    return (
      <SecureLayout>
        <NotFound/>
      </SecureLayout>
    )
  }
  return (
    <>
      <SecureLayout>
        <MapAllowedRoutes routes={PrivateRoutesConfig} />
      </SecureLayout>
    </>
  );
}

export default PrivateRoutes;
