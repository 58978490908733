import React from "react";
import { FieldProps, getIn } from "formik";
import {
  FormFieldProps,
  Form,
  Label,
  Popup,
  Button
} from "semantic-ui-react";

interface IProps extends FieldProps<string, HTMLInputElement>, FormFieldProps {}

export const TextInput = (props: IProps) => {

  const {
    field,
    form: { touched, errors },
    label,
    placeholder,
    type,
    disabled,
    tooltip,
    required
  } = props;

  return (
    <Form.Field type={type}>
      <label>
        {label}
        {required && <> *</>}
        {tooltip && (
          <Popup
            content={tooltip}
            trigger={<Button type="button" className="tooltip" icon="info circle" />}
          />
        )}
      </label>

      <Form.Input
        {...field}
        type={type}
        placeholder={placeholder}
        onBlur={field.onBlur}
        disabled={disabled}
      />
      {getIn(touched, field.name) && getIn(errors, field.name) && (
        <Label
          size="large"
          color="red"
          icon="times circle"
          content={(getIn(errors, field.name))}
        />
      )}
    </Form.Field>
  );
};
