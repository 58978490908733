import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import {createBrowserHistory} from 'history';
import { Login } from './pages/login/Login';
import PrivateRoutes from './routes/PrivateRoutes';

// sessionguard, notificationstatus, errorboundary

export const history = createBrowserHistory();

export const ApplicationRouter = () => {
  return (
    <Router history={history}>
        <Switch>
          <Route path="/login" component={Login} />

          <Route path="/dashboard">
            <PrivateRoutes />
          </Route>
        </Switch>
    </Router>
  )
}
