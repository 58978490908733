import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import "semantic-ui-css/semantic.min.css";
import ModalContainer from "./components/common/modals/ModalContainer";
import { ApplicationRouter } from "./ApplicationRouter";
import { RootStoreContext } from "./stores/RootStore";
import { history } from './ApplicationRouter'


function App() {
  const rootStore = useContext(RootStoreContext);
  const { isAuthenticated } = rootStore.authorizationStore;

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push('/login')
    }
  }, [])

  return (
    <div className="App">
      <ModalContainer />
      <ApplicationRouter />
    </div>
  );
}

export default observer(App);
