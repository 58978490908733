import React, { useContext, useState } from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react'
import "./Login.styles.scss"
import { LoginForm } from '../../components/login/LoginForm';

export const Login = () => {

  return (
    <>
    <div className="main">
      <Grid className="login" columns="2" padded>
        <Grid.Column id="login-illustration" />
        <Grid.Column id="login-block">
        <div id="login-form">
            <Header as='h1' textAlign='center'>Login</Header>
            <Segment>
             <LoginForm/>
            </Segment>
            </div>
        </Grid.Column>
      </Grid>
    </div>
    </>
  )
}